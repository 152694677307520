.boxselector_wrapper > div,
.box-selector-item {
  flex: 1;
}

.boxselector_wrapper .boxselector_header,
.box-selector-item .boxselector_header {
  font-size: 18px;
  margin-bottom: 5px;
  margin-right: 20px;
  font-weight: bold;
  user-select: none;
  color: var(--text-boxselector-header);
}

.boxselector_wrapper input[type='radio'],
.box-selector-item input[type='radio'] {
  display: none;
}

.boxselector_wrapper label,
.box-selector-item label {
  @apply border border-solid;
  @apply bg-gray-2 border-gray-5 text-black;
  @apply th-dark:bg-gray-iron-10 th-dark:border-gray-neutral-8 th-dark:text-white;

  font-weight: normal;
  font-size: 12px;
  display: block;
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  box-shadow: var(--shadow-boxselector-color);
  position: relative;

  text-align: left;
  height: 100%;
}

/* not disabled */
.boxselector_wrapper input[type='radio']:not(:disabled) ~ label,
.box-selector-item input[type='radio']:not(:disabled) ~ label {
  background-color: var(--bg-boxselector-color);

  box-shadow: none;
  cursor: pointer;
}

/* disabled */
.box-selector-item input:disabled + label,
.boxselector_wrapper label.boxselector_disabled {
  @apply !bg-white;
  @apply th-dark:!bg-gray-7;
  @apply th-highcontrast:!bg-black;
  filter: opacity(0.3) grayscale(1);

  cursor: not-allowed;
  pointer-events: none;
}

.boxselector_wrapper label.boxselector_disabled a {
  cursor: pointer;
  pointer-events: auto;
}

.boxselector_wrapper input[type='radio']:checked + label,
.box-selector-item input[type='radio']:checked + label {
  @apply bg-blue-2 border-blue-6;
  @apply th-dark:bg-blue-10 th-dark:border-blue-7;

  background-image: url(../../../assets/ico/checked.svg);
  background-repeat: no-repeat;
  background-position: right 15px top 15px;

  border-radius: 8px;
  padding: 15px;
  box-shadow: none;
}

@media only screen and (max-width: 700px) {
  .boxselector_wrapper {
    flex-direction: column;
  }
}

.box-selector-item.limited.business label,
.box-selector-item.limited.business input[type='radio']:checked + label {
  @apply border-warning-7 bg-warning-1 text-black;
  @apply th-dark:bg-warning-3;
}

.boxselector_img_container {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;

  line-height: 90px;
  margin-bottom: 0;
}

.boxselector_icon,
.boxselector_icon img {
  font-size: 90px;
}

.boxselector_icon > svg {
  margin-left: -5px;
}

.boxselector_header pr-icon {
  margin-right: 5px;
}

.boxselector_content {
  padding-left: 20px;
}

.boxselector_img_container {
  line-height: 90px;
  margin-bottom: 0;
}

.box-selector-item p {
  margin-bottom: 0;
}
